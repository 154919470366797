import { createSelector } from 'reselect';
import StoryChief from '../../storychief';

const getLock = (state) => state.lock;
const getPresence = (state) => state.presence;
const getThisMember = (state) => state.presence.find((member) => member.id === StoryChief.user.id);

export const getModelIsLocked = createSelector(getLock, (lock) => !!(!lock || !lock.token));
export const getUserHasLock = createSelector(
  getLock,
  (lock) => !!(lock && lock.user && lock.user.id === StoryChief.user.id),
);

export const getUserWithLock = createSelector(getLock, (lock) => lock?.user?.firstname);

export const getSomeoneHasLock = createSelector(getLock, (lock) => !!(lock && lock.user));

export const getUserIsRequestingLock = createSelector(
  getThisMember,
  (member) => !!(member && member.isRequestingLock),
);

export const getLockIsAbandoned = createSelector(getLock, getPresence, (lock, members) => {
  if (!(lock && lock.user && lock.user.id)) return true; // there is no lock or no lock user
  if (!members || !members.length) return false; // if presence is not loaded, we can not know yet

  return !members.find((member) => member.id === lock.user.id);
});

export default getModelIsLocked;
